html {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}

.App {
  width: 100%;
}

#root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  // flex-direction: row;
  // background-color: red;
}

// input:-internal-autofill-selected {
//   background-color: transparent !important; /* Transparent background */
//   color: #000 !important; /* Ensure text remains visible */
//   box-shadow: 0 0 0px 1000px transparent inset !important; /* Remove autofill background color */
//   transition: background-color 0.3s ease-in-out; /* Optional smooth transition */
//   appearance: unset;
// }

// input:-webkit-autofill,
// textarea:-webkit-autofill,
// select:-webkit-autofill {
//   background-color: transparent !important;
//   box-shadow: 0 0 0px 1000px transparent inset !important;
//   color: inherit !important;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  color: inherit !important;
}
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  color: inherit !important;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  color: inherit !important;
}
.success-info {
  color: #059b9a;
}

.error-info {
  color: #ce423a;
}

.divider-main {
  margin: 30px 0 20px 0 !important;
}

.create-account {
  font-family: "Inter", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px !important;

  .account {
    font-size: 13px;
    font-family: "Inter", sans-serif;
    color: #565656;
    font-weight: bold;
    margin-left: 5px;
  }
}

.right-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  padding: 0 84px;
  height: 100%;
  flex: 1;
}

.loading {
  background-color: #e8f1ec;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-message {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #ce423a;
}

.main {
  margin-left: 230px;
}

.center-logo {
  display: flex;
  justify-content: start;
  align-items: center;
}

.select-option-container {
  height: 43px !important;
  margin-top: -1px !important;
}
