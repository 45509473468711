.counts-container1 {
  width: 50%;
  height: auto;
  gap: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.counts-container {
  width: 96%;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: relative;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: #e8f1ec;
  flex-shrink: 0;
}

.Bottom-Right-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.counts {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 4px;
  font-size: 18px;
}

.count {
  font-size: 32px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.count-name {
  font-size: 18px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.sml-button-view {
  background-color: #e8f1ec !important;
  color: #059b9a !important;
  width: 80px;
  height: 35px;
  padding: 0 !important;
  border-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-color: #059b9a !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  transition: background-color 0.3s ease;
}

.sml-button-view:hover {
  background-color: #d6eceb !important;
}

.sml-button-add {
  background-color: #059b9a !important;
  color: #fff !important;
  width: 80px;
  height: 35px;
  padding: 0 !important;
  border-radius: 0 !important;
  border-bottom-right-radius: 10px !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  transition: background-color 0.3s ease;
}

.sml-button-add:hover {
  background-color: #047d7d !important;
}

.sml-button-view.Mui-disabled {
  color: grey !important;
  border-color: lightgrey !important;
  background-color: #f0f0f0 !important;
}
