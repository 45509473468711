.edit-modal {
  .cancel-button {
    color: #000000 !important;
    background-color: #d7d7d7 !important;
    border-color: #d7d7d7 !important;
    text-transform: capitalize !important;
    width: 170px;
    font-family: "Inter", sans-serif !important;

    &:hover {
      border-color: #d7d7d7 !important;
    }
  }

  .MuiDialog-paper {
    max-width: 351px !important; // Custom width
    max-height: 241px !important; // Custom height
    margin: 0px;
    border-radius: 20px; // Optional: Add rounded corners
    padding: 30px;
  }

  .header {
    display: flex;
    justify-content: center;
    padding: 0px;
  }

  .h6 {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    font-family: "Inter", sans-serif !important;
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
  }

  .dialog-action-wrap {
    padding: 0px !important;
  }

  .MuiPaper-root-MuiDialog-paper {
    padding: 0px;
  }

  .body-container {
    overflow-y: hidden;
    padding: 0;
  }

  .edit-button {
    color: #ffffff !important;
    background-color: #1f00cf !important;
    text-transform: capitalize !important;
    width: 170px;
    font-family: "Inter", sans-serif !important;

    &:hover {
      border-color: #1f00cf !important;
    }
  }

  .delete-message {
    color: #000000 !important;
    font-family: "Inter", sans-serif !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  // .model-container {
  // }

  &.model-container {
    .MuiDialog-container {
      border-radius: 30px !important;
      width: 100%;
      margin: auto;
    }

    .MuiPaper-root {
      border-radius: 15px !important;
    }
  }

  .css-izme17-MuiTypography-root {
    margin-top: 0px !important;
  }
}
