.css-kw13he-MuiDialogContent-root {
  padding: 0px 0px !important;
}

.amenity-form {
  width: 400px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  &__header {
    background-color: #e9e8e8;
    padding: 10px 0;
    text-align: center;
  }

  &__title {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }

  &__content {
    padding: 16px;
  }

  &__label {
    font-size: 16px;
    font-family: "Inter", sans-serif;
    margin-bottom: 8px;
  }

  &__field {
    margin-bottom: 16px;
  }

  &__input {
    .MuiOutlinedInput-root {
      fieldset {
        border-radius: 10px;
      }
      height: 45px;
      display: flex;
      align-items: center;
    }
  }

  // &__error {
  //   color: #d32f2f;
  //   text-align: center;
  //   margin-bottom:12px;
  //   margin-left: 0px;
  // }

  &__success {
    color: #388e3c;
    text-align: center;
    margin-bottom: 16px;
  }
}
