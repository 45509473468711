.text-input-field {
  .text-field-label2 {
    font-size: 15px !important;
    font-family: "Inter", sans-serif !important;
    color: #616161 !important;
    justify-content: center !important;
    align-items: center !important;
    line-height: 14px !important;
  }
  .MuiOutlinedInput-root {
    border-radius: 10px !important;
    width: 100% !important;
    height: 45px !important;
    margin-bottom: 5px;
  }
  p.Mui-error {
    position: absolute;
    bottom: -13px !important;
    left: 0px;
    padding: 0;
  }
}
