.left-menu {
  background: transparent;
  background-color: #059b9a;
  // min-height: 100vh;
  min-height: calc(100% - 33px);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 193px;
  padding-left: 37px;
  padding-top: 33px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 18px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0;
    height: 65px;
    margin-right: -12px;
    width: 175px;

    &.active {
      // background-color: #F8F8F8;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      color: #059b9a;
      transition: color 1s ease-out;
      background: url("./../../assets/img/menu-curve.png") no-repeat left;
      background-size: contain;
    }

    &:hover {
      color: #059b9a;
      // background-color: #F8F8F8;
      transition: color 1s ease-out;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      transition: color 1s ease-out;
      background: url("./../../assets/img/menu-curve.png") no-repeat left;
      background-size: contain;
    }
  }

  a {
    display: flex;
    width: 100%;
    line-height: 52px;
    text-decoration: none;
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding-left: 10px;

    &.active {
      color: #059b9a;
      transition: color 0.5s ease-out;
    }

    &:hover {
      color: #059b9a;
      transition: color 0.5s ease-out;
    }
  }

  .logo {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  img {
    width: 121px;
    height: auto;
    padding-left: 18px;
  }
}
