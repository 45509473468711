.left-side {
  background-image: linear-gradient(
      rgba(122, 205, 161, 0.6) 0%,
      rgba(5, 155, 154, 0.6) 100%
    ),
    url("./../../assets/img/bb.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 510px);
  height: auto;

  .bottom {
    position: absolute;
    bottom: 50px;
    color: #ffffff;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 0px;
    background-color: transparent;
    width: 592px;
    height: 62px;
    line-height: 30px;
  }

  .animation {
    position: relative;
    width: 380px;
    height: 500px;

    /* Rectangle Styling */
    .animated-rectangle1 {
      background-color: #7acda1;

      border-radius: 10px;
      width: 180px;
      height: 180px;
      color: #ffffff;
      font-weight: bold;
      font-size: 16px;
      font-family: "Kadwa", sans-serif;
      text-align: center;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
      overflow: hidden;
    }

    /* Sliding animation */
    .animated-rectangle1:nth-child(1) {
      animation: slide-1 5s infinite ease-in-out;
    }

    .animated-rectangle1:nth-child(2) {
      animation: slide-2 5s infinite ease-in-out;
    }

    .animated-rectangle1:nth-child(3) {
      animation: slide-3 5s infinite ease-in-out;
    }

    .animated-rectangle1:nth-child(4) {
      animation: slide-4 5s infinite ease-in-out;
    }
    @keyframes slide-1 {
      0% {
        transform: translate(0px, -100px);
      }
      25% {
        transform: translate(0px, 0px);
      }
      50% {
        transform: translate(0px, 100px);
      }
      75% {
        transform: translate(0px, 200px);
      }
      100% {
        transform: translate(0px, -100px);
      }
    }

    @keyframes slide-2 {
      0% {
        transform: translate(0px, 100px);
      }
      25% {
        transform: translate(0px, 200px);
      }
      50% {
        transform: translate(0px, 300px);
      }
      75% {
        transform: translate(200px, 200px);
      }
      100% {
        transform: translate(0px, 100px);
      }
    }

    @keyframes slide-3 {
      0% {
        transform: translate(200px, 100px);
      }
      25% {
        transform: translate(200px, 0px);
      }
      50% {
        transform: translate(200px, -100px);
      }
      75% {
        transform: translate(0px, 0px);
      }
      100% {
        transform: translate(200px, 100px);
      }
    }
    @keyframes slide-4 {
      0% {
        transform: translate(200px, 300px);
      }
      25% {
        transform: translate(200px, 200px);
      }
      50% {
        transform: translate(200px, 100px);
      }
      75% {
        transform: translate(200px, 0px);
      }
      100% {
        transform: translate(200px, 300px);
      }
    }
    .animated-rectangle2 {
      background-color: #53ba83;
      border-radius: 10px;
      width: 180px;
      height: 180px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-size: 87px 87px;
      background-repeat: no-repeat;
    }

    /* Sliding animation */
    .animated-rectangle2:nth-child(1) {
      animation: slide-1 5s infinite ease-in-out;
    }

    .animated-rectangle2:nth-child(2) {
      animation: slide-2 5s infinite ease-in-out;
    }

    .animated-rectangle2:nth-child(3) {
      animation: slide-3 5s infinite ease-in-out;
    }

    .animated-rectangle2:nth-child(4) {
      animation: slide-4 5s infinite ease-in-out;
    }
    @keyframes slide-1 {
      0% {
        transform: translate(0px, -100px);
      }
      25% {
        transform: translate(0px, 0px);
      }
      50% {
        transform: translate(0px, 100px);
      }
      75% {
        transform: translate(0px, 200px);
      }
      100% {
        transform: translate(0px, -100px);
      }
    }

    @keyframes slide-2 {
      0% {
        transform: translate(0px, 100px);
      }
      25% {
        transform: translate(0px, 200px);
      }
      50% {
        transform: translate(0px, 300px);
      }
      75% {
        transform: translate(200px, 200px);
      }
      100% {
        transform: translate(0px, 100px);
      }
    }

    @keyframes slide-3 {
      0% {
        transform: translate(200px, 100px);
      }
      25% {
        transform: translate(200px, 0px);
      }
      50% {
        transform: translate(200px, -100px);
      }
      75% {
        transform: translate(0px, 0px);
      }
      100% {
        transform: translate(200px, 100px);
      }
    }
    @keyframes slide-4 {
      0% {
        transform: translate(200px, 300px);
      }
      25% {
        transform: translate(200px, 200px);
      }
      50% {
        transform: translate(200px, 100px);
      }
      75% {
        transform: translate(200px, 0px);
      }
      100% {
        transform: translate(200px, 300px);
      }
    }
  }
}
