.text-style {
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.card-main-container {
  padding: 0px;

  .MuiTypography-root {
    line-height: 2;
  }
}

.card-main-header {
  font-size: 18px;
  font-weight: 500;
}

.card-main-subheader {
  font-size: 14px;
  color: #666;
}

.input-container {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.input-box {
  flex-grow: 1;
  position: relative;
}

.input-label {
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.94px;
}

.input-error {
  color: #d32f2f;
  font-size: 12px;
}

.tenant-info-container {
  .add-icon-position {
    position: absolute;
    bottom: -35px;
    left: 4%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .minus-icon-position {
    position: absolute;
    right: -5%;
    top: 66%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .input-container {
    .MuiBox-root {
      margin-bottom: 6px;
    }
  }
}
