.pagination-controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;

  button {
    font-size: 14px;
    cursor: pointer;
    gap: 10;
    padding: 4px 0;
    margin: 0;
    color: #939393;
    border: none;
    background: none;
    font-family: "Inter", sans-serif;

    //    justify-content: space-between;
    &.active {
      color: #000000;
      text-decoration: underline;
      text-underline-offset: 2px;
      // font-weight: bold;
      font-family: "Inter", sans-serif;
      text-decoration-thickness: 1px;
    }

    &:hover {
      color: #000000;
    }

    &.pagination-btn {
      text-decoration: none;
    }
  }

  .pagination-info {
    font-size: 14px;
    color: #333333;
    font-family: "Inter", sans-serif;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;

    .pagination-info {
      font-size: 12px;
    }

    button {
      font-size: 12px;
      padding: 3px 6px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;

    button {
      font-size: 12px;
      padding: 3px 6px;
    }

    .pagination-info {
      font-size: 12px;
    }
  }

  .dropdown {
    width: 46px;
    height: 25px;
    list-style: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    border-radius: 2px;

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-sc8y68-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
      padding-right: 6px;
      font-size: 14px;
      font-family: "Inter", sans-serif;
      text-align: center;
      box-sizing: border-box;
      border-radius: 2px;
    }

    .MuiSelect-select {
      padding: 0 15px !important;
      width: 16px;
      line-height: 25px;
      text-align: center;
      height: 25px;
    }

    .MuiSvgIcon-root {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
    }

    .text-entries {
      padding: 0px !important;
      margin-left: 6px;
    }
  }

  .css-si86to-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    overflow: unset !important;
    padding: 20px;
  }

  .css-1n12lg0-MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto;
    width: 100%;
  }
}

.MenuItems .MuiList-root {
  width: 46px;
  padding: 0px !important;

  //   .MuiList-root{
  //     padding: 0px !important;
  //   }

  //   .MuiMenuItem-root {
  //     border: 0px;
  //     width: 100%;
  //     margin: 0;
  //     padding: 5px !important;
  //     border-bottom: 1px solid #E9E9E9;
  //   }
  // }
}
