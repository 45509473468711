.name-collection {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;

  .sign-up-container {
    height: auto;
    display: flex;
    flex-direction: row;
  }

  .sign-in-button {
    margin-top: 10px !important;
  }
}
