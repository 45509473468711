.main-profile-container .MuiPaper-root {
  background-color: transparent;

  .profile-container {
    background-color: #ffffff;
    height: 587px;
    width: 580px;
    border-radius: 10px;
  }

  .profile-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: none;
    height: 126px;
    width: 100%;
    margin-top: 20.5px;
    margin-bottom: 24px;
    background-color: transparent;
  }

  .text-field {
    margin-top: 4px;
    margin-left: -2px;
    margin-bottom: 2px;
  }
  .profile-img {
    width: 126px;
    height: 126px;
    border-radius: 50%;
    margin-left: 19px;
  }

  .top-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e8e8;
    height: 57px;
    width: 580px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    font-family: "Inter";
    font-weight: 600;
  }

  .edit-icon {
    width: 33px;
    height: 33px;
    box-shadow: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    font-size: 20px;
    position: relative;
    top: 51px;
    right: 33px;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translate(-28px, 50px);
  }

  .cancel-button {
    background-color: #c23434 !important;
    color: #ffffff !important;
    border-radius: 10px;
    width: 95px;
    height: 39px;
    text-transform: capitalize !important;
    font-size: 16px !important;
    margin-right: 12px;
    font-family: "Inter";

    &:hover {
      background-color: darken(#c23434, 10%);
    }
  }

  .submit-button {
    background-color: #059b9a;
    color: #ffffff;
    border-radius: 10px;
    width: 95px;
    height: 39px;
    text-transform: capitalize !important;
    font-size: 16px !important;
    font-family: "Inter";

    &:hover {
      background-color: darken(#059b9a, 10%);
    }
  }

  .text {
    font-family: "Inter";
  }
}
