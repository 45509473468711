.parent-details {
  display: flex;
  width: 100%;
  flex-direction: column;

  .add-property-button {
    height: 95px !important;
    width: 100%;
    margin-right: 0px !important;
    font-size: 16px !important;
    padding: 18px !important;
    border: 1px dashed #aaa7a7 !important;
    border-radius: 8px !important;
    background-color: #e4eeee !important;
    color: #0c0636 !important;
    gap: 10px !important;
    font-family: "Inter", sans-serif !important;
    text-transform: none !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    box-shadow: none !important;

    // Responsive adjustments
    @media (max-width: 600px) {
      height: 8vh !important;
      font-size: 14px !important;
      padding: 16px !important;
    }
  }

  .property-details-box {
    display: flex;
    align-items: center;
    width: calc(100% - 38px);
    margin-right: 0px !important;
    background-color: #e4eeee !important;
    border: 1px dashed #aaa7a7 !important;
    border-radius: 8px !important;
    padding: 18px !important;

    .property-logo {
      margin-right: 60px;
      width: 66px;
      height: 66px;
      border-radius: 100%;
      min-width: 66px;
      object-fit: cover;
    }

    .property-details-content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 60px;
      width: 100%;

      .property-detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;

        .property-detail-label {
          font-family: "Inter", sans-serif !important;
          color: #8f8f8f !important;
          margin-bottom: 5px;
          font-size: 14px;
        }
        .property-detail-label .MuiSvgIcon-root {
          font-size: 16px;
          margin-bottom: -3px;
        }

        .property-detail-value {
          font-family: "Inter", sans-serif !important;
          color: #0c0636 !important;
          text-align: left;
          font-size: 16px;
          white-space: normal;
        }
      }
    }
    // Media Query for Smaller Screens
    @media (max-width: 600px) {
      width: 90%;
      margin-right: 0 !important;
      padding: 10px !important;

      .property-logo {
        margin-right: 30px;
        width: 50px;
        height: 50px;
      }

      .property-details-content {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
      }

      .property-detail-icon {
        font-size: 18px;
        margin-top: 12px;
      }
    }
  }
}
