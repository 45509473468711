.sign-up {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;

  .sign-up-container {
    height: auto;
    display: flex;
    flex-direction: row;
  }

  .create-account {
    margin-bottom: 12px;
  }

  .privacy-container {
    font-size: 13px;
    font-weight: 400;
    font-family: "Inter" !important;
  }

  .privacy {
    color: #6083ff;
    font-weight: 600;
    font-family: "Inter" !important;
  }

  .no-wrap-helper-text {
    white-space: nowrap;
  }
}
