.admin {
  &-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;

    .menu {
      background-color: #059b9a;
      height: 100vh;
      margin: 0;
      padding: 0;
    }

    main {
      flex: 1 1;
      padding-left: 37px;
      padding-right: 37px;
      background: linear-gradient(to left, #e8f1ec, #f8f8f8);
      overflow-y: auto;
      height: 100vh;
    }
  }

  &-hoc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 33px 0px 35px 0;
  }

  &-heading {
    h1 {
      font-size: 24px;
      margin: 0 0 5px 8px;
    }
    span {
      font-size: 16px;
      padding: 8px;
    }
  }
}
