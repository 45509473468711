.header-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 163px;
    display: block;
    height: 39px;
    margin-bottom: 25px;
  }

  .logo-img {
    object-fit: contain;
    width: 100%;
  }

  .title {
    font-family: '"Inter", sans-serif';
    margin-bottom: 18px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: center;
  }

  .sub-title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    margin-bottom: 2px;
  }
}
