.properties-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // height: 100%;
  // background-color: #f8f8f8;
  padding: 0px 16px 16px 16px;

  .admin-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}
