.sign-in-button {
  margin: 0rem;
  background-image: linear-gradient(45deg, #059b9a 0%, #7acda1 100%);
  color: white;
  opacity: 100%;
  text-transform: none;
  width: 100%;
  height: 45px;
  font-size: 16px !important;
  font-family: "Inter", sans-serif !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
}
