.verifying {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;

  .verifying-container {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .sign-in-button {
    margin-top: 12px !important;
  }

  .tagline-f {
    font-size: 14px !important;
    line-height: 18px !important;
  }
}
