.data-table {
  display: flex;
  width: 100%;
  margin-top: 50px;
  flex-direction: column;

  .add-regional-property-button {
    height: 39px !important;
    width: 291px !important;
    font-size: 16px !important;
    background-color: #059b9a !important;
    color: white;
    border-radius: 7px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    gap: 7px !important;
    font-family: "Inter", sans-serif !important;
    padding: 6px 11px !important;

    @media (max-width: 600px) {
      width: 100% !important;
      font-size: 12px !important;
    }
  }

  .table-container {
    margin: 0px;
    width: 100%;
    overflow-x: auto;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 20px;
  }

  .table-heading {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-align: left;
    color: #059b9a;
    padding: 10px 10px 22px 22px;
    border-bottom: 1px solid #059b9a;
    width: fit-content;
    margin: 0;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 30px !important;
    }
  }

  .property-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    margin-top: -20px;
    thead {
      th {
        padding: 20px !important;
        text-align: left;
        border: none;
        font-family: "Inter", sans-serif !important;
        color: #0c0636;
        font-size: 16px !important;
        font-family: Inter;
        font-weight: 500;
      }
      tr {
        height: 80px !important;
        border-top: 1px solid #e7e6e6 !important;
      }
    }

    tbody {
      tr {
        height: 80px !important;
        font-family: "Inter", sans-serif !important;
        color: #0c0636;
        font-size: 16px;

        &:nth-child(even) {
          background-color: #f8f8f8 !important;
        }

        &:nth-child(odd) {
          background-color: white !important;
        }

        td {
          padding: 20px !important;
          text-align: left;
          border: none;
          cursor: pointer;
        }
      }
    }

    .th-head {
      cursor: pointer;
      color: #000000;
      text-decoration: none;
    }
  }
}
