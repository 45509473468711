.admin {
  &-heading {
    display: flex;
    flex-direction: row;

    h1 {
      font-size: 24px;
      margin: 0 0 5px 8px;
      font-family: "Inter";
      font-weight: 700;
      text-align: left;
    }

    span {
      font-size: 16px;
      padding: 8px;
      font-family: "Inter";
      font-weight: 400;
      text-align: left;
    }

    .icons {
      font-size: 30px;
      font-weight: bolder;
    }
  }
}
