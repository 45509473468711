.email-input-field {
  margin-top: 8px !important;

  &.hidden-asterisk {
    display: none;
  }

  .MuiOutlinedInput-root {
    border-radius: 10px !important;
    width: 100%;
    height: 48px !important;
  }
  .text-field-label1 {
    font-size: 15px !important;
    font-family: "Inter", sans-serif !important;
    color: #616161 !important;
    justify-content: center !important;
    align-items: center !important;
    line-height: 14px !important;
  }
  p.Mui-error {
    position: absolute;
    bottom: -18px !important;
    left: 0px;
    padding: 0;
  }

  .text-field1,
  .css-16bevx5-MuiFormControl-root-MuiTextField-root {
    margin-top: 8px;
  }
}
