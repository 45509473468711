.snackbar {
  .MuiSnackbar-root {
    background-color: #279f9b;
  }
}
.custom-alert {
  background-color: #279f9b;
  border-radius: 5px;
  color: #fff; // Text color for default alerts
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  width: 367px !important;
  height: 60px !important;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Success Variant */
  &.MuiAlert-standardSuccess {
    background-color: #eefaef;
    color: #529c4f;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: linear-gradient(to right, #529c4f 75%, #eefaef 50%);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  /* Error Variant */
  &.MuiAlert-standardError {
    background-color: #fdf5f5;
    color: #ce423a;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: linear-gradient(to right, #ce423a 75%, #fdf5f5 50%);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &.MuiAlert-standardInfo {
    background-color: #eefaef;
    color: #529c4f;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: linear-gradient(to right, #529c4f 75%, #eefaef 50%);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}
